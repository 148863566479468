export const LABEL_ABBREVIATIONS = {
  'directional wind': 'Dir',
  'pedestrian wind comfort': 'PLW',
  'thermal comfort': 'TC',
  'solar': 'Sun',
  'solar study': 'Sun',
  'solar analysis': 'Sun',
  'ventilation potential': 'Vent',
  'cladding': 'Clad',
  'acoustics': 'Acou',
  'ground': 'Grd',
  'balcony': 'Bal',
  'podium': 'Pod',
  'arrow': 'Arw',
  'rooftops': 'Rf',
  'iso-lines': 'Iso-l',
  'iso-surface': 'Iso-s',
  'grade': 'Grd',
  'above grade': 'A-grd',
  'horizontal streamline': 'SL-H',
  'vertical streamline': 'SL-V',
  'hotspot streamline': 'SL-HS',
  'streamline horizontal': 'SL-H',
  'streamline vertical': 'SL-V',
  'streamline hotspot': 'SL-HS',
  'rwdi calculation': 'RWDI',
  'rwdi comfort criteria': 'RWDI',
  'markham comfort criteria': 'Mark',
  'lawson comfort criteria': 'Law',
  'city of london comfort criteria': 'CoL',
  'san francisco comfort criteria': 'SF',
  'boston comfort criteria': 'Bos',
  'boston comfort criteria (simplified)': 'Bos',
  'montreal comfort criteria': 'Mon',
  'hamilton comfort criteria': 'Ham',
  'burlington comfort criteria': 'Bur',
  'mississauga comfort criteria': 'Miss',
  'guelph comfort criteria': 'Gue',
  'rwdi plw criteria': 'RWDI',
  'rwdi comfort criteria (simplified)': 'RWDI',
  'markham plw criteria': 'Mark',
  'lawson plw criteria': 'Law',
  'lawson comfort criteria (simplified)': 'Law',
  'city of london plw criteria': 'CoL',
  'san francisco plw criteria': 'SF',
  'boston plw criteria': 'Bos',
  'montreal plw criteria': 'Mon',
  'hamilton plw criteria': 'Ham',
  'burlington plw criteria': 'Bur',
  'mississauga plw criteria': 'Miss',
  'guelph plw criteria': 'Gue',
  'city of london tc': 'CoL-TC',
  'toronto tc': 'TO-TC',
  'toronto comfort criteria (simplified)': 'TO',
  'spmv': 'SPMV',
  'spmv*': 'SPMV',
  'spmvs': 'SPMV',
  'spmv hot': 'SPMV',
  'spmv* hot': 'SPMV',
  'spmvs hot': 'SPMV',
  'spmv average hot': 'SPMV',
  'spmv* average hot': 'SPMV',
  'spmvs average hot': 'SPMV',
  'spmv averages temperate': 'SPMV',
  'spmv* averages temperate': 'SPMV',
  'spmvs averages temperate': 'SPMV',
  'spmv - percentage comfortable': 'SPMV %Cmf',
  'spmv* percent time comfortable': 'SPMV %Cmf',
  'spmv - percentage acceptable': 'SPMV %Acp',
  'spmv* percent time acceptable': 'SPMV %Acp',
  'spmv - percentage too hot': 'SP-hot',
  'spmv - percentage too cold': 'SP-cold',
  'utci': 'UTCI',
  'utci %-inbounds': 'UTCI-In',
  'utci percent time no thermal stress': 'UTCI %NTS',
  'abridged comfort criteria': 'Abr',
  'mean wind speed': 'WS',
  '% sun lit': '%Lit',
  'solar exposure': '%Exp',
  'daylight hours': 'Hrs Lit',
  'custom': 'cust',
  'windspeed velocity ratio': 'VR',
  'spmvs %-inbounds': 'SP-In',
  'spmvs %-outbounds': 'SP-Out',
  'statistical averaged wind speed': 'SAWS',
  'statistical average windspeed': 'SAWS',
  'morning': 'Mor',
  'midday': 'Mid',
  'afternoon': 'Aft',
  'evening': 'Eve',
  'night': 'Ngt',
  'summer': 'Sum',
  'winter': 'Win',
  'autumn': 'Aut',
  'spring': 'Spr',
  'monsoon': 'Mon',
  'football': 'FB',
  'wet': 'Wet',
  'dry': 'Dry',
  'walking': 'Walk',
  'leisurely walking': 'Walk',
  'business walking': 'Walk',
  'sociallight sport': 'Sprt',
  'spectating': 'Spec',
  'standing': 'Stand',
  'dining': 'Dine',
  'northern temperate casual': 'Cas',
  'northern temperate business casual': 'B. Cas',
  'northern temperate business': 'Bus',
  'northern moderate casual': 'Cas',
  'northern moderate business casual': 'B. Cas',
  'northern moderate business': 'Bus',
  'tropical casual': 'Cas',
  'tropical business casual': 'B. Cas',
  'tropical business': 'Bus',
  'tropical beach': 'Beach',
  'tropical dining': 'Dine',
  'shorts': 'Shts',
  'wind speed ratio': 'WR',
  'climate scaled wind speed': 'CSW',
  'wind cooling potential': 'WCP',
  'ava': 'AVA',
  'furniture movement potential': 'Furn',
  'relative mean wind pressure': 'Mean Cp',
  'mean wind pressure coefficients': 'Mean Cp',
  'relative max wind pressure': 'Max Cp',
  'max wind pressure coefficients': 'Max Cp',
  'relative min wind pressure': 'Min Cp',
  'min wind pressure coefficients': 'Min Cp',
  'relative rms wind pressure': 'RMS Cp',
  'rms wind pressure coefficients': 'RMS Cp',
  'relative wind pressure': 'Cp',
  'wind pressure coefficients': 'Cp',
  'relative worst max wind pressure': 'Max Cp Worst',
  'relative worst min wind pressure': 'Min Cp Worst',
  'relative worst rms wind pressure': 'RMS Cp Worst',
  'worst case wind pressure coefficients': 'Cp Worst',
  'worst case wind pressure estimates': 'Est Worst',
  'wind intensity': 'int',
  'abridged wind comfort criteria': 'abr',
  'worst case wind pressure upx': 'UpX',
  '4 point plots': 'Plots',
  'static structural loading': 'Struct',
  'forces and moments': 'Forces',
  'force coefficients': 'Cf',
  'drag and lift coefficients': 'Drag',
  'statistical wind': 'Stat Wnd',
  'open': 'Open',
  'site': 'Site',
  'pet': 'PET',
  'average daily par': 'PAR',
  'clear sky solar access': 'Acc Hrs',
  'clear sky solar exposure': '%Exp',
  'sky view': '%Sky'
};
